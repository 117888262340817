.navigation__container {
  background-color: var(--nav-color);
}

.navbar-light .navbar-nav .nav-link {
  color: var(--background-color) !important;
}

.nav-link {
  background-color: var(--nav-color);
  transition: all 0.3s ease;
}

.nav-link:hover {
  background-color: #f2d4a6;
  transition: all 0.3s ease;
}

/* .navbar-light .navbar-brand {
  color: var(--font-color) !important;
} */

.navbar-light .navbar-toggler-icon {
  filter: invert(1);
}

.links a:hover {
  background-color: #f2d4a6;
}