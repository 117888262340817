@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');



.App {
  font-family: 'Nunito', sans-serif;


}

:root {
  --nav-color: linear-gradient(to top left, #d98928 0%, #935509 100%);
  --nav-color: rgb(251, 194, 86);
  --background-color: linear-gradient(to top left, #c3c0c0 0%, rgb(52, 34, 1) 100%);
  --nav-color-hover: #fcf2e8;
  --nav-border-color: hsl(210, 11%.45%);
  --light-color: hsl(209, 100%, 92%);
  --font-color: hsl(0, 0%, 100%);
  --font-color-hover: hsl(0, 0%, 40%);
  --portfolio-background: rgb(94, 90, 84);
  --about: rgb(5, 5, 4);

}