.portfolio__main__container {

  color: rgb(5, 5, 4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 72%;
  margin: 0 auto;
  padding-top: 3%;
  border-radius: solid 1px rgb(5, 5, 4);

}

.portfolio__image {
  height: 11rem;
  border-radius: 3px;
  object-fit: fill;
  border-bottom: 5px solid var(--nav-color);
  border-top: 5px solid var(--nav-color);
  opacity: 0.8;

}

.modal-header,
.modal-footer {
  background-image: var(--background-color);
  color: var(--font-color);
}

.modal-body {
  background-color: rgb(244, 242, 238);
  color: var(--font-color-hover);
  text-align: center;
}

.portfolio__main__container h1,
.portfolio__main__container p {
  text-align: center;
  margin-top: 3rem;
}

#portfolio__card__container {
  width: 33%;
  padding: 10px;
  border: none;
  background-color: var(--background-color);
  border-radius: 3px;

}

.portfolio__image:hover {
  cursor: pointer;
  filter: brightness(50%);
}

.portfolio__click__info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--font-color);
  display: none;
}

.portfolio__image:hover+.portfolio__click__info {
  display: block;
}

.modal-backdrop.show {
  opacity: 0.1 !important;
}

#portfolio__modal__link {
  padding: 0 0 1rem 1rem;
  background-color: rgb(244, 242, 238);
  color: var(--font-color-hover);
}

.portfolio__main__container a {
  transition: all 0.3s ease;
}

#portfolio__modal__link:link {
  text-decoration: none;
  text-align: center;
}

.modal-open .portfolio__main__container {
  opacity: 0;
}

@media only screen and (max-width: 768px) {


  #portfolio__card__container {
    width: 100%;
    padding: 2% 10%;
  }

  .portfolio__image {
    height: 8rem;


  }


}


@media only screen and (max-width: 1000px) and (min-width: 768px) {
  #portfolio__card__container {
    width: 50%;
    /* height: 11rem; */
    /* padding: 1rem 2.5rem; */
    border: none;
  }

  .portfolio__image {

    border-radius: 3px;
    object-fit: fill;

  }


}