.technologies__container {
  /* height: 100vh; */
  /* background-image: var(--background-color); */
  color: rgb(5, 5, 4);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8%;
  box-sizing: content-box;
  border-bottom: 5px solid var(--nav-color);

}

.tech__cards__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1100px;
  margin: 1rem 0;
}

.tech__card {
  width: 15rem;
  height: 10rem;
  background-color: var(--font-color);
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border-bottom: 5px solid var(--nav-color);
  transition: all 0.1s;
}

.tech-header {}

.tech__icons {
  height: 70px;
  background-color: var(--light-color);
  border-radius: 50%;
}

@media screen and (max-height: 1368px) {
  .tech-header {
    padding: 3%;
    /* font-size: medium; */
  }



}

@media only screen and (max-width: 900px) {
  .technologies__container {
    padding: 25% 0;
  }

}

@media screen and (max-width: 420px) {
  .tech-header {
    font-size: medium;
  }
}