.home {
  align-items: center;
  font-family: 'Courier New', Courier, monospace;
  color: #fba92c;
  /* background-image: var(--background-color); */
  padding: 8% 0;
}


/* .about img {
  object-fit: cover;
  object-position: top left;

  width: 200px;
  height: 220px;
  border-radius: 50%;
  border: 1px solid #201815;
} */

.about {
  width: 100%;
  height: 100vh;
  height: calc(100vh -100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--background-color);
  /* padding-top: 10%; */
  border-bottom: 5px solid var(--nav-color);
}

.about h2 {

  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  font-size: 5rem;
  color: #f2d4a6;
  text-shadow: 2px 2px 9px #f6cd90;
  height: 50px;
  padding-bottom: 7%;
}

.about .prompt {
  width: 40%;
  font-size: 30px;
  color: #fcf2e8;
  padding-top: 2%;

}

.prompt svg {
  font-size: 3rem;
  margin: 5px;
  color: #fcf2e8;
}

.smedia {
  cursor: pointer;
}



@media only screen and (max-width: 912px) {
  .about {
    padding-bottom: 20%;
    padding-top: 0;

  }

  .about h2 {
    font-size: 3rem;
    padding-bottom: 10%;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }

  /* .about img {
    width: 140px;
    height: 170px;
  } */
}

@media only screen and (max-width: 300px) {
  .about h2 {
    font-size: 2rem;
  }

}