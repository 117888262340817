.footer {
  width: 100%;
  height: 100px;
  background: var(--nav-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;


}

.media {
  color: #565656;
  cursor: pointer;
}

.icons:hover {
  background-color: #b9b6b6;
}

.footer p {
  color: #565656;
}

@media only screen and (max-width: 600px) {
  .footer svg {
    font-size: 50px;
  }
}